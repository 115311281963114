import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import "../styles/variables.css"

const Button = styled.button`
    // margin-top: 40px;
    cursor: pointer;
    width: clamp(140px, 16vw, 200px);
    height: 50px;
    border: none;
    font: inherit;
    font-family: "Biko", sans-serif;
    outline: inherit;
    border-radius: var(--btn-radius);
    background-color: var(--magenta-100);
    color: var(--white);
    border: 1px solid var(--magenta-100);
    transition: .3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
`

const CTAButton = ({to, content}) => (
  <Link to={to}>
  <Button>{content}</Button>  
  </Link>
)

export default CTAButton
