import React from "react"
import styled from "styled-components"
import { graphql} from "gatsby"
// import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTAButton from "../components/CTAButton"

import EventImage from "../assets/event-pic.jpg"
import RecweekBG from "../assets/recweek-bg.png"
import "../styles/variables.css"
import ScrollAnimation from 'react-animate-on-scroll'

// components
import Navigation from "../components/Nav"
import Footer from "../components/Footer"

const Wrapper = styled.div`

    background: linear-gradient(180deg, #090836 30%, #7D2047, #090836, var(--indigo-300));

    > div, > section {
        padding: 0 40px;
      }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
const RecweekHeaderWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 3em;
    z-index: 101;
    
    h1 {
        margin: 3.6em 0 0.6em;
        font-size: var(--header-2);
        color: #DFA839;
        font-family: Magnificent;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
        text-shadow: 0px 2px 0px #201A2C;
    
        @media screen and (max-width: 600px){
          line-height: 1.5em;
        }
    }

    p {
        font-family: "Biko", sans-serif;
        color: var(--white);
        text-align: center;
        padding: 0 40px;
    }
`

const EventWrapper = styled.section`
    display: flex;
    width: 100%;
    max-width: 1200px;
    z-index: 110;
    margin: auto;

    .detail-container {
        display: flex;
        flex-direction: column;
        width: 60%;    
        font-family: "Biko", sans-serif;
    }

    img {
        width: 40%;
    }

    .event-tag {
        text-transform: uppercase;
        color: var(--magenta-100);
        font-weight: 700;
        margin-bottom: 20px;
    }

    .date-location {
        text-transform: uppercase;
        color: var(--purple-100);
        margin-bottom: 16px;
    }

    h4 {
        font-weight: 900;
        font-size: 1.6em;
        color: var(--yellow);
        margin-bottom: .6em;
    }

    p {
        color: var(--white);
        padding-right: 4em;
    }

    @media screen and (max-width: 900px){
        flex-direction: column;

        .detail-container {
            width: 100%;
            margin-bottom: 20px;
        }

        img {
            width: 100%;
        }

        p {
            padding: 0;
            margin-bottom: 20px;
        }
    }
    
`

const RSVP = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: ;
    margin-top: 4em;
    margin-bottom: clamp(28vw, -5vw, 10em);
    max-width: 1200px;
    // background: url(${RecweekBG});
    // background-position: bottom;
    // background-repeat: no-repeat;
    // background-size: cover;
    z-index: 103;

    h3 {
      font-size: var(--header-3);
      font-family: "The Nightmare", serif;
      color: var(--yellow);
      text-shadow: 0px 2px 0px #201A2C;
    }

    p {
      margin: 8px 0 40px;
      color: var(--white);
      font-family: "Biko", sans-serif;
    }

    a {
        width: fit-content;
    }

    .bg {
        position: absolute;
        bottom: 190px;
        width: 100%;
        z-index: -1;
        left: 0;
    }
`


export default ({data}) => {
    return (
        <Layout>
            <SEO title="Join LIONS RecWeek this September 5 - 7 at the Colayco Pavillion"></SEO>
            <Wrapper>
                <Navigation logo={data.logo} smallLogo={data.smallLogo} />
                <div className="content-wrapper">
                <RecweekHeaderWrapper>
                  {/* <ScrollAnimation animateIn="fadeUp" animateOnce="true"> */}
                    <h1>JOIN THE TEAPARTY</h1>
                  {/* </ScrollAnimation> */}
                  <ScrollAnimation animateIn="fadeUp" animateOnce="true" delay="200">
                    <p>LIONS RecWeek is an exciting avenue for students to join organizations and witness the various passions, advocacies, and services they can take part in! This year, we enjoin everybody to celebrate the diversity of independent organizations emerging towards one empowered and vibrant community.</p>
                  </ScrollAnimation>
                </RecweekHeaderWrapper>
                <ScrollAnimation animateIn="fadeUp" delay="400">
                <EventWrapper>
                    <div className="detail-container">
                        <span className="event-tag">Upcoming Event</span>
                        <span className="date-location">August 29 - September 7 | Colayco Pavilion</span>
                        <h4>LIONS Recruitment Week (RecWeek)</h4>
                        <p>LIONS RecWeek is an exciting avenue for students to join organizations and witness the various passions, advocacies, and services they can take part in! This year, we enjoin everybody to celebrate the diversity of independent organizations emerging towards one empowered and vibrant community.</p>
                    </div>
                    <img src={EventImage} alt="Representatives of LIONS organizations holding a banner of their org logo " />
                </EventWrapper>
                </ScrollAnimation>
                <RSVP>
                  <ScrollAnimation animateIn="fadeFromRight">
                    <h3>Ready to experience Wonderland?</h3>
                    <p>RSVP to LIONS RecWeek activities and events!</p>
                    <CTAButton to="/organizations" content="Join the Tea Party!" />
                    </ScrollAnimation>
                    <img className="bg" src={RecweekBG} />
                </RSVP>
                </div>
                <Footer data={data} margin="-30%" style={{ zIndex: 999 }}/>
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
  query RecWeekImages {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 112) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    smallLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    group: file(relativePath: { eq: "group_pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    big: file(relativePath: { eq: "bigpic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cards: allPrismicOrgCard {
      edges {
        node {
          data {
            org__name {
              text
            }
            org_description {
              text
            }
            org_logo {
              localFile {
                absolutePath
                childImageSharp {
                  fixed(quality: 100, width: 40) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            org_tags {
              text
            }
            org_category_list {
              org_category
            }
          }
        }
      }
    }
  }
`
